<main class="main">
    <section class="introduction">
        <div class="introduction_container">
            <div class="info">
                <h2 class="title static-page-title" translate>App.HomePage.IntroductionSection.Title</h2>
                <p class="text static-page-paragraph-text"
                   [innerHTML]="'App.HomePage.IntroductionSection.Text' | translate"></p>
                <a mat-flat-button
                   [routerLink]="'/' + AppRoutes.CountMeIn"
                   queryParamsHandling="merge"
                   (click)="sendCountMeInAnalytics()"
                   class="btn join-cmi-btn center button button_small"
                   color="primary">
                    {{'App.HomePage.JoinCountMeInButton' | translate}}
                </a>
            </div>
            <img src="assets/images/patients.png"
                 class="image"
                 [alt]="'App.HomePage.IntroductionSection.ImageAlt' | translate">
        </div>
        <div class="logos">
            <img src="assets/images/logo-broad.svg"
                 alt="Broad Institute logo"
                 width="124" height="32">
            <img src="assets/images/dana-farber-logo.png"
                 alt="Dana-Farber Cancer Institute logo"
                 width="124" height="32">
        </div>
    </section>

    <section class="learn-more">
        <h2 class="title static-page-title" translate>App.HomePage.LearnMoreSection.Title</h2>
        <p class="text static-page-paragraph-text" [innerHTML]="'App.HomePage.LearnMoreSection.Text' | translate"></p>
        <a mat-stroked-button
           [routerLink]="'/' + AppRoutes.AboutUs"
           queryParamsHandling="merge"
           class="btn learn-more-btn center small-letter-spacing button button_small button_secondary">
            {{'App.HomePage.LearnMoreSection.LearnMoreAboutUsButton' | translate}}
            <mat-icon>chevron_right</mat-icon>
        </a>
    </section>

    <section class="join">
        <img class="image"
             lazy-resource
             src="assets/images/patient1.png"
             [alt]="'App.HomePage.JoinSection.ImageAlt' | translate"/>
        <div class="description">
            <h2 class="title static-page-title" translate>App.HomePage.JoinSection.Title</h2>
            <p class="text static-page-paragraph-text" [innerHTML]="'App.HomePage.JoinSection.Text1' | translate"></p>
            <p class="text static-page-paragraph-text" [innerHTML]="'App.HomePage.JoinSection.Text2' | translate"></p>
        </div>
        <img class="image"
             lazy-resource
             src="assets/images/patient2.png"
             [alt]="'App.HomePage.JoinSection.ImageAlt' | translate"/>
    </section>

    <section class="participate">
        <app-participation-section></app-participation-section>
    </section>

    <section class="faq">
        <h2 class="title static-page-title" translate>App.FAQ.Title</h2>
        <div class="container">
            <app-faq-section questions="App.FAQ.Questions" questionsCount="5"></app-faq-section>
        </div>
        <a mat-stroked-button
           [routerLink]="'/' + AppRoutes.FAQ"
           queryParamsHandling="merge"
           class="btn more-faq-btn center small-letter-spacing button button_small button_secondary">
            {{'App.FAQ.Button.MoreFAQs' | translate}}
            <mat-icon>chevron_right</mat-icon>
        </a>
    </section>

    <section class="stay-informed">
        <app-stay-informed-section></app-stay-informed-section>
    </section>

    <section class="join-cmi">
        <app-join-cmi-section></app-join-cmi-section>
    </section>
</main>
